import React from 'react';
import styled from 'styled-components';

import { Button } from '@components/form-elements/buttons/Button';

import { Wrapper } from './styles';
import { useInitContext } from '@providers/InitProvider';
import { CheckEmail } from '@components/icons/CheckEmail';

const StyledIconWrapper = styled.div`
  width: 60px;
  margin: 0 auto;
`;

export const RateLimitPage = () => {
  const { logout } = useInitContext();

  const goToLogin = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    logout();
  };

  return (
    <Wrapper>
      <div className="root">
        <StyledIconWrapper>
          <CheckEmail className="checkEmailIcon" />
        </StyledIconWrapper>
        <h1 className="great">Error!</h1>
        <h2 className="accountDeactivated">You made too many requests to our servers</h2>
        <h2 className="pleaseContact">
          Please do not use automation software on this website otherwise your account will be blocked
        </h2>

        <Button size="medium" onClick={goToLogin}>
          Sign In
        </Button>
      </div>
    </Wrapper>
  );
};
